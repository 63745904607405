/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-key, no-nested-ternary */

import { FC, useMemo, useState, useRef, useEffect } from 'react';
import { FranchiseListSearchItem } from 'interfaces/content/Ranking';
import { SearchFilter } from 'interfaces/components/SearchFilter';
import { usePagination, useSortBy, useTable } from 'react-table';
import Button from 'components/Globals/Base/Button';
import Dropdown from 'components/Globals/Base/Dropdown';
import Input from 'components/Globals/Base/Input';
import { ContentTitle } from 'components/ContentTitle';
import createColumns from 'components/Ranking/SearchTable/createColumn';
import { substractLastSlash } from 'utils/stringUtils';
import { useRouter } from 'next/router';
import toKebabCase from 'lodash.kebabcase';
import { RANKING_V2_PAGE } from 'constants/testsIds/rankingV2Page';
import { RestoreColumns, Table, TablePagination, WrapperTable } from './SearchTableStyles';

export interface RankingSearchTableProps {
  items: FranchiseListSearchItem[];
  sortableFields: SearchFilter[];
  initialRowCount?: number;
}

const RankingSearchTable: FC<RankingSearchTableProps> = ({ items, sortableFields, initialRowCount = 10 }) => {
  const data = useMemo(
    () =>
      items.map(
        (franchiseListItem) =>
          ({
            Name: franchiseListItem.name,
            Order: franchiseListItem.order,
            Rank: franchiseListItem.rank,
            ...franchiseListItem.data,
            slug: `${franchiseListItem.slug}/`,
          } as { [key: string]: string | number }),
      ),
    [items],
  );
  const tablePaginationRef = useRef<HTMLDivElement | null>(null);
  const [scrollTable, setScrollTable] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState({});
  const isSearchPage = useRouter().route.split('/').includes('search');
  const columns = useMemo(() => createColumns(sortableFields, isSearchPage), [sortableFields]);
  const useTableInstance = (pageSize: number) =>
    useTable(
      {
        columns,
        data,
        initialState: {
          // @ts-ignore
          pageIndex: 0,
          pageSize,
          sortBy: [
            {
              desc: false,
              id: sortableFields.find((el: SearchFilter) => el.title === 'Rank') ? 'Rank' : 'Name',
            },
          ],
        },
        onColumnVisibilityChange: setColumnVisibility,
        state: {
          columnVisibility,
        },
      },
      useSortBy,
      usePagination,
    );

  const tableInstance = useTableInstance(data.length >= 50 ? initialRowCount : data.length);
  const tableInstanceSeo = useTableInstance(data.length);

  const filterSeoTable = <T extends { id: number | string }>(tablePage: T[], seoTablePage: T[]) => {
    const idsToRemove = tablePage.map((item) => item.id);
    return seoTablePage.filter((item) => !idsToRemove.includes(item.id));
  };

  const filteredSeoTable = filterSeoTable(tableInstance.page, tableInstanceSeo.page);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageOptions,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = tableInstance;

  useEffect(() => {
    if (scrollTable && tablePaginationRef.current) {
      tablePaginationRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setScrollTable(false);
    }
  }, [scrollTable]);

  return (
    <>
      {tableInstance.visibleColumns.length !== columns.length && (
        <RestoreColumns>
          <ContentTitle
            tag='h3'
            size={20}
          >
            {' '}
            Restore Columns
          </ContentTitle>
          {tableInstance.columns.reduce((acc: JSX.Element[], curr) => {
            if (!curr.isVisible) {
              acc.push(
                <Button
                  className='btn-restore'
                  variant='secondary'
                  onClick={() => {
                    curr.toggleHidden();
                  }}
                >
                  {curr.Header}
                </Button>,
              );
            }
            return acc;
          }, [])}
        </RestoreColumns>
      )}
      <WrapperTable>
        <Table
          {...getTableProps()}
          visibleRows={pageSize}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    className={column.isSorted ? (column.isSortedDesc ? 'sort-asc' : 'sort-desc') : ''}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    data-cy={`th-${index}`}
                  >
                    <div
                      tabIndex={0}
                      role='button'
                      className='remove'
                      onClick={(e) => {
                        e.preventDefault();
                        column.toggleHidden();
                      }}
                      onKeyUp={(e) => {
                        e.preventDefault();
                        column.toggleHidden();
                      }}
                      data-cy={`remove-${index}`}
                    >
                      Remove
                    </div>
                    <div
                      className='column-title'
                      data-cy={`head-${toKebabCase(column.Header?.toString())}`}
                    >
                      {column.render('Header')}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.length === 0 && (
              <tr data-cy={RANKING_V2_PAGE.FILTER_NO_RESULTS}>
                <td
                  className='no-results'
                  colSpan={headerGroups[0].headers.length}
                >
                  No results found
                </td>
              </tr>
            )}
            {page.length !== 0 &&
              page.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    data-cy={RANKING_V2_PAGE.LIST_ROW}
                  >
                    {row.cells.map((cell, idx) => (
                      <td
                        {...cell.getCellProps()}
                        className={
                          (idx === 0 && isSearchPage) ||
                          (idx === 0 && sortableFields.find((el: SearchFilter) => el.title === 'Rank')) ?
                            'ordered-ranking-column' :
                            ''
                        }
                        data-cy={RANKING_V2_PAGE.LIST_CELL}
                      >
                        {cell.value ? cell.render('Cell') : '-'}
                      </td>
                    ))}
                    <td>
                      <a
                        href={substractLastSlash(row.original.slug as string)}
                        className='row-link hidden-text'
                        aria-label={`Read more about ${row.values.Name}`}
                      >
                        View More details about {row.values.Name}
                      </a>
                    </td>
                  </tr>
                );
              })}
            {filteredSeoTable.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  data-cy={RANKING_V2_PAGE.LIST_ROW}
                >
                  {row.cells.map((cell, idx) => (
                    <td
                      {...cell.getCellProps()}
                      className={
                        (idx === 0 && isSearchPage) ||
                        (idx === 0 && sortableFields.find((el: SearchFilter) => el.title === 'Rank')) ?
                          'ordered-ranking-column' :
                          ''
                      }
                      data-cy={RANKING_V2_PAGE.LIST_CELL}
                    >
                      {cell.value ? cell.render('Cell') : '-'}
                    </td>
                  ))}
                  <td>
                    <a
                      href={substractLastSlash(row.original.slug as string)}
                      className='row-link hidden-text'
                      aria-label={`Read more about ${row.values.Name}`}
                    >
                      View More details about {row.values.Name}
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </WrapperTable>
      <TablePagination ref={tablePaginationRef}>
        <div className='previous'>
          <Button
            type='button'
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            variant='tertiary'
            hasRightArrow={false}
            hasLeftArrow
            dataCy={RANKING_V2_PAGE.FILTER_PREV_PAGE}
          >
            Previous
          </Button>
        </div>
        <div className='page-info'>
          <span className='page-number'>
            Page{' '}
            <Input
              type='number'
              aria-label='Page Number'
              value={pageIndex + 1 || 1}
              size={{ default: 'small' }}
              dataCy={RANKING_V2_PAGE.FILTER_CURRENT_PAGE_INPUT}
              onChange={(newValue) => {
                const newPage = newValue ? Number(newValue) - 1 : 0;
                gotoPage(newPage);
              }}
            />{' '}
            of {pageOptions.length}
          </span>
          <span className='page-size'>
            <Dropdown
              placeholder='Number of rows'
              aria-label='Number of rows'
              onOptionClick={(value) => {
                setPageSize(parseInt(value, 10));
                setScrollTable(true);
              }}
              list={[5, 10, 20, 25, 50, 100].map((val) => ({ label: `${val} Rows`, value: `${val}` }))}
              selected={`${pageSize} Rows`}
              size='small'
              dataCy={RANKING_V2_PAGE.FILTER_ROWS_TO_SHOW}
              className='dropdown'
            />
          </span>
        </div>
        <div className='next'>
          <Button
            type='button'
            onClick={() => nextPage()}
            disabled={!canNextPage}
            variant='tertiary'
            dataCy={RANKING_V2_PAGE.FILTER_NEXT_PAGE}
          >
            Next
          </Button>
        </div>
      </TablePagination>
    </>
  );
};
export default RankingSearchTable;

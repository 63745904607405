export const checkArrayValidity = <T>(arr: Array<T>) => arr && arr.length > 0;

export const sortData = (type: string) => {
  switch (type) {
    case 'number':
    case 'percent':
    case 'money':
      return (val1: string, val2: string) => {
        let newCurr: any = val1;
        let newNext: any = val2;
        if (typeof val1 !== 'number') {
          // force null and undefined to the bottom
          newCurr =
            val1 === null || undefined === val1 || val1 === '' ? -Infinity : parseFloat(val1.replace(/[^\d.-]*/g, ''));
        }
        if (typeof val2 !== 'number') {
          newNext =
            val2 === null || undefined === val2 || val2 === '' ? -Infinity : parseFloat(val2.replace(/[^\d.-]*/g, ''));
        }

        return newCurr > newNext ? 1 : -1;
      };
    case 'text':
    default:
      return (val1: string, val2: string) => {
        // force null and undefined to the bottom
        let newVal1 = val1 === null || undefined === val1 ? -Infinity : val1;
        let newVal2 = val2 === null || undefined === val2 ? -Infinity : val2;

        // force any string values to lowercase
        newVal1 = typeof newVal1 === 'string' ? newVal1.toLowerCase() : newVal1;
        newVal2 = typeof newVal2 === 'string' ? newVal2.toLowerCase() : newVal2;

        // Return either 1 or -1 to indicate a sort priority
        if (newVal1 > newVal2) {
          return 1;
        }
        if (newVal1 < newVal2) {
          return -1;
        }
        // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
        return 0;
      };
  }
};

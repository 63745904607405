import Slider from 'react-slick';
import PrevArrow from 'components/Arrows/PrevArrow';
import NextArrow from 'components/Arrows/NextArrow';
import { trackEvent } from 'services/Gtm/functions';
import { EventData } from 'services/Gtm/entities';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Button from 'components/Globals/Base/Button';
import * as S from './SlideFilterStyles';

export interface ResponsiveSettings {
  breakpoint: number;
  settings: {
    slidesToScroll: number;
    slidesToShow: number;
  };
}

export interface SlidePickerProps {
  listType?: 'grid' | 'list' | undefined;
  valueList: string[];
  selectedValue: string;
  changeSelectedValue: (newValue: string) => Promise<boolean> | void;
  trackingData?: EventData;
  availableValues?: string[];
  responsiveSettings?: ResponsiveSettings[];
  className?: string;
  itemClassName?: string;
  slidesToShowNr?: number;
  dataCy?: string;
}

const SlideFilter = ({
  listType,
  valueList,
  selectedValue,
  changeSelectedValue,
  trackingData,
  availableValues,
  responsiveSettings,
  className,
  itemClassName = '',
  dataCy,
}: SlidePickerProps) => {
  const settings = {
    accessibility: true,
    dots: false,
    infinite: false,
    initialSlide: valueList?.indexOf(selectedValue),
    nextArrow: <NextArrow dataCy={`${dataCy}-next-arrow`} />,
    prevArrow: <PrevArrow dataCy={`${dataCy}-prev-arrow`} />,
    responsive: responsiveSettings,
    slidesToScroll: 4,
    slidesToShow: 6,
    speed: 500,
  };

  if (!valueList || valueList.length === 0) return null;

  return (
    <S.SlideFilterWrapper
      className={className}
      $listType={listType}
      data-cy={dataCy}
    >
      {/* If the valueList array has just one element, there is no need to render the slider */}
      {valueList.length === 1 ? (
        <Button
          tabIndex={0}
          variant='tertiary'
          hasRightArrow={false}
          className={`item ${selectedValue === valueList[0] ? 'current' : ''}  ${itemClassName}  ${
            !availableValues?.includes(valueList[0]) ? 'disabled' : ''
          }`}
          data-cy={`${dataCy}-value`}
        >
          {valueList[0]}
        </Button>
      ) : (
        <Slider {...settings}>
          {valueList.map((value) => {
            const buttonClass = `item ${selectedValue === value ? 'current' : ''} ${itemClassName} ${
              availableValues && !availableValues?.includes(value) ? 'disabled' : ''
            }`;
            return (
              <>
                <Button
                  key={value}
                  variant='tertiary'
                  hasRightArrow={false}
                  className={buttonClass}
                  onClick={() => {
                    /* eslint-disable-next-line no-void */
                    void changeSelectedValue(value);
                    if (trackEvent && trackingData) trackEvent({ ...trackingData, eventLabel: `value : ${value}` });
                  }}
                  dataCy={`${dataCy}-${value}`}
                >
                  {value}
                </Button>
                <span className={`${buttonClass} placeholder`}>{value}</span>
              </>
            );
          })}
        </Slider>
      )}
    </S.SlideFilterWrapper>
  );
};

export default SlideFilter;

import styled from 'styled-components';
import { font, breakpoint } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';

export const WrapperTable = styled.div`
  overflow: auto;
`;

export const Table = styled.table<{ theme: Theme; visibleRows: number }>`
  width: 100%;
  border-spacing: 0;

  thead {

    & tr {

      & th {
        border-bottom: 1px solid ${getColor('borderSecondary')};
        text-align: left;
        position: relative;
        color: ${getColor('textSecondary')};
        font-family: ${font.graphikCond};
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 1px;
        font-size: 12px;
        line-height: 18px;
        vertical-align: top;
        width: 100px;

        @media (${breakpoint.lgMax}) {
          min-width: 100px;
        }

        &:nth-of-type(1) {
          width: 40px;

          @media (${breakpoint.lgMax}) {
            min-width: 40px;
          }

          .remove {
            visibility: hidden;
          }
        }

        &:nth-of-type(2) {
          width: 200px;

          @media (${breakpoint.lgMax}) {
            min-width: 150px;
          }

          .remove {
            visibility: hidden;
          }
        }

        &.sort-asc {
          color: ${getColor('neutralSoftBlack')};

          &::after {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBmaWxsLW9wYWNpdHk9IjAiIHN0cm9rZT0iIzAwMDAwMCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0iYXJjcyI+PHBhdGggZD0iTTkgMThsNi02LTYtNiIvPjwvc3ZnPgo=);
            background-size: 100%;
            content: ' ';
            display: block;
            height: 20px;
            position: absolute;
            right: 2px;
            top: 38px;
            transform: rotate(-90deg);
            width: 20px;
          }
        }

        &.sort-desc {
          color: ${getColor('neutralSoftBlack')};
          
          &::after {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBmaWxsLW9wYWNpdHk9IjAiIHN0cm9rZT0iIzAwMDAwMCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0iYXJjcyI+PHBhdGggZD0iTTkgMThsNi02LTYtNiIvPjwvc3ZnPgo=);
            background-size: 100%;
            content: ' ';
            display: block;
            height: 20px;
            position: absolute;
            right: 2px;
            top: 38px;
            transform: rotate(90deg);
            width: 20px;
          }
        }

        & .remove {
          color: ${getColor('textLink')};
          margin-bottom: 8px;
          display: flex;
          flex-wrap: nowrap;

          &:after {
            content: url("/icons/CloseIconRed.svg");
            margin-left: 4px;
          }
        }

        & .column-title {
          padding-right: 12px;
        }
      }
    }
  }

  th, td {
    padding: 12px 4px;
  }

  tbody {
    & tr {
      height: 72px;
      box-sizing: content-box;
      display: none;

      ${({ visibleRows }) => `
        &:nth-child(-n+${visibleRows}) {
          display: table-row;
        }
      `}
      
      &:hover {
        background-color: ${getColor('surfaceBrand')};
        color: ${getColor('textLink')};
      }

      td {
        font-size: 16px;
        font-family: ${font.graphikCond};
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 24px;
        cursor: pointer;
        width: 100px;
        position: relative;

        @media (${breakpoint.lgMax}) {
          min-width: 100px;
        }

        .row-link {
          position: absolute;
          display: block;
          min-width: 1440px;
          height: 100%;
          top: 0;
          right: 0;
        }

        .hidden-text {
          color: transparent;
        }

        &:last-child {
          max-width: 0;
          padding: 0;

          @media (${breakpoint.lgMax}) {
            min-width: 0;
          }
        }

        &:nth-of-type(1) {
        width: 200px;
        padding-left: 16px;
        
        &.ordered-ranking-column {
          font-size: 20px;
          font-weight: 700;
          color: ${getColor('neutralDarkGray')};
          width: 40px;
        }     

          @media (${breakpoint.lgMax}) {
            min-width: 40px;
          }

          &.no-results {
            font-size: 18px;
            font-weight: 400;
            padding: 20px;
            letter-spacing: 0;
            text-align: center;
            font-family: ${font.graphikCond};
          }
        }

        &:nth-of-type(2) {
          width: 200px;

          @media (${breakpoint.lgMax}) {
            min-width: 150px;
          }
        }
      }

      &:nth-of-type(2n+1) {
        background-color: ${getColor('surfaceSecondary')};

        &:hover {
          background-color: ${getColor('surfaceBrand')};
        }
      }
    }
  }
`;

export const TablePagination = styled.div<{ theme: Theme }>`
  box-shadow: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 20px 16px 0 16px;

  @media (${breakpoint.mdMin}) {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    border: 0;
    box-shadow: none;
    flex-flow: row wrap;
  }

  & .previous, .next {
    flex: 0 0 calc(50% - 10px);
    margin-bottom: 10px;
    width: calc(50% - 10px);

    @media (${breakpoint.mdMin}) {
      flex: 0 0 25%;
      margin-bottom: 0;
    }

    button {
      width: 100%;
      height: 100%;

      &:disabled {
        visibility: hidden;
      }
    }
  }

  & .previous {
    order: 0;
  }

  & .next {
    order: 1;

    @media (${breakpoint.mdMin}) {
      order: 2;
    }
  }

  & .page-info {
    display: flex;
    flex: 1 0 100%;
    width: 100%;
    order: 2;
    align-items: center;

    @media (${breakpoint.mdMin}) {
      flex: 1 0 auto;
      width: auto;
      margin-bottom: 0;
      justify-content: space-around;
      order: 1;
    }

    select {
      border: 1px solid ${getColor('borderBlack')};
      font-family: ${font.graphikCond};
      font-size: 16px;
      font-weight: 600;
      height: 2.5rem;
      letter-spacing: 0.5px;
      line-height: 24px;
      padding: 5px 10px;

      &:hover, &:focus-visible {
        outline: 0;
      }
    }

    .page-number {
      display: inline-block;
      margin: 3px 10px;
      font-family: ${font.graphikCond};
      letter-spacing: 1px;
      margin-right: 10px;

      @media (${breakpoint.mdMin}) {
        margin-right: 1px;
      }

      input {
        width: 70px;
        text-align: center;
      }
    }

    .page-size {
      flex: 1 0 auto;
      position: relative;

      @media (${breakpoint.mdMin}) {
        flex: 0 0 50%;
        position: relative;
        padding-right: 0;
      }

      select {
        width: 100%;
      }
    }
  }

`;

export const RestoreColumns = styled.div<{ theme: Theme }>`
  padding: 16px;

  h3 {
    border-bottom: 1px solid ${getColor('borderBlack')};
    font-weight: 600;
    letter-spacing: 0.144px;
    margin-bottom: 8px;
    padding-bottom: 8px;
  }

  .btn-restore {
    font-weight: 600;
    margin: 0 8px 8px 0;
    padding: 8px;
    text-transform: capitalize;
  }

`;

import Input from 'components/Globals/Base/Input';
import Button from 'components/Globals/Base/Button';
import { useEffect, useState } from 'react';
import SearchWrapper from './SearchStyles';

interface SearchProps {
  initialValue: string;
  placeholder?: string;
  dataCy?: string;
  size?: 'large' | 'small';
  onChange: (value: string) => void;
}

export const SearchWButton = ({
  initialValue,
  placeholder = 'Looking for something?',
  size = 'large',
  onChange,
  dataCy = '',
}: SearchProps) => {
  const [search, setSearch] = useState(initialValue);
  useEffect(() => {
    setSearch(initialValue);
  }, [initialValue]);
  return (
    <SearchWrapper $size={size}>
      <Input
        placeholder={placeholder}
        value={search}
        data-cy={`${dataCy}-input`}
        size={{ default: size }}
        variant={size === 'small' ? 'discrete' : 'default'}
        onChange={setSearch}
        onKeyDown={(e) => (e.key === 'Enter' ? onChange(search) : '')}
      />
      <Button
        onClick={() => onChange(search)}
        variant={size === 'small' ? 'primaryBrand' : 'primary'}
        size={{ default: size }}
        hasRightArrow={false}
        dataCy={`${dataCy}-action`}
      >
        Search
      </Button>
    </SearchWrapper>
  );
};

export default SearchWButton;

import styled from 'styled-components';
import { breakpoint, color } from 'styles/globals';

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  @media (${breakpoint.xlMin}) {
    padding: 0 24px 24px;
  }

  @media (${breakpoint.xxlMin}) {
    padding: 0 48px 24px;
  }

  .year-filter {
    max-width: 100%;
    width: 100%;
    padding: 0 16px;

    @media (${breakpoint.mdMin}) {
      max-width: 40%;
      width: 40%;
    }

    @media (${breakpoint.lgMin}) {
      max-width: 45%;
      width: 45%;
    }
  }
  
  @media (${breakpoint.mdMax}) {
    flex-direction: column;
    .year-filter {
      margin-top: 24px;
      max-width: 100%;
    }
  }
`;

export const FiltersWrapper = styled.div`
  background-color: ${color.lightBlue};
  padding: 0 48px;

  @media (${breakpoint.xlMax}) {
    padding: 0 24px;
  }

  @media (${breakpoint.mdMax}) {
    padding: 0 16px;
  }
`;

export const FiltersHeader = styled.div<{ $display: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row nowrap;
  padding: 16px 0;

  ${({ $display }) =>
    $display &&
    `
    border-bottom: 1px solid ${color.primaryBlack};
  `}
  .title {
    user-select: none;

    svg {
      height: 18px;
      width: 16px;
      margin-right: 12px;
      cursor: pointer;
    }
  }

  button {
    padding: 8px;

    &.hidden {
      visibility: hidden;
    }
  }
`;

export const FiltersContainer = styled.form`
  padding: 24px 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  
  .dropdown, .checkbox {
    flex: 0 0 100%;
   

    @media (${breakpoint.mdMin}) {
      flex: 0 0 calc(50% - 12px);
      margin-right: 12px;
    }

    @media (${breakpoint.xlMin}) {
      flex: 0 1 calc(25% - 36px);
      margin-right: 36px;
    }
    
    select {
      width: 100%;
      height: 42px;
      padding: 8px 16px;
      font-weight: 600;
    }
  }

  .checkbox {
    margin-bottom: 16px;
  }

  .dropdown {
    margin-bottom: 20px;
    label {
      text-transform: uppercase;
      font-weight: 600;
    }
  }
`;

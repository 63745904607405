/* eslint-disable @typescript-eslint/no-unsafe-return */
import { SearchFilter } from 'interfaces/components/SearchFilter';
import { Accessor, Row } from 'react-table';
import { sortData } from 'utils/arrayUtils';

const createColumns = (sortableFields: SearchFilter[], isSearchPage: boolean) => {
  const sortFn = (type: string) => (row1: Row, row2: Row, columnId: string) => {
    const val1: string = row1.values[columnId] as string;
    const val2: string = row2.values[columnId] as string;
    return sortData(type.toLowerCase())(val1, val2);
  };

  // string value can't be used because some column titles contain dots, which are being interpreted as nested accessors
  // switching to custom accessor function
  const accessCellForRow = (accessor: string) => (row: { [key: string]: string | number }) => row[accessor];

  return sortableFields.reduce(
    (
      acc: {
        Header: string;
        accessor: Accessor<{ [key: string]: string | number }>;
        title: string;
        type: string;
        sortType: any;
      }[],
      curr: {
        title: string;
        type: string;
      },
    ) => {
      // Rename Ranking to Rank if it's the case; e.g. World's Most Admired Companies in 2019
      if (curr.title === 'Ranking') {
        // eslint-disable-next-line no-param-reassign
        curr.title = 'Rank';
      }
      if (curr.title === 'Company' || curr.title === 'Company Name' || curr.title === 'Full Name') {
        // Skip column if present; duplicators for Name column
        return acc;
      }
      // Inserts a column for the company title when producing initial array of columns.
      if (curr.title === 'Rank' || curr.title === 'Order') {
        return acc.concat(
          {
            Header: curr.title,
            accessor: accessCellForRow(curr.title),
            sortType: sortFn(curr.type),
            title: curr.title,
            type: curr.type,
          },
          {
            Header: 'Name',
            accessor: accessCellForRow('Name'),
            sortType: sortFn('Text'),
            title: 'Name',
            type: 'Text',
          },
        );
      }
      // Inserts a column for the company name and rank when sortable field is Ordering
      if (curr.title === 'Ordering') {
        if (isSearchPage) {
          return acc.concat(
            {
              Header: 'Order',
              accessor: accessCellForRow('Order'),
              sortType: sortFn(curr.type),
              title: curr.title,
              type: curr.type,
            },
            {
              Header: 'Name',
              accessor: accessCellForRow('Name'),
              sortType: sortFn('Text'),
              title: 'Name',
              type: 'Text',
            },
          );
        }
        return acc.concat({
          Header: 'Name',
          accessor: accessCellForRow('Name'),
          sortType: sortFn('Text'),
          title: 'Name',
          type: 'Text',
        });
      }

      return acc.concat({
        Header: curr.title,
        accessor: accessCellForRow(curr.title),
        sortType: sortFn(curr.type),
        title: curr.title,
        type: curr.type,
      });
    },
    [],
  );
};

export default createColumns;

import styled from 'styled-components';
import { breakpoint } from 'styles/globals';

const SearchWrapper = styled.div<{ $size: string }>`
  width: 100%;
  min-width: 0;
  max-width: ${({ $size }) => ($size === 'large' ? '480px' : '100%')};
  display: flex;
  flex-wrap: nowrap;

  @media (${breakpoint.mdMin}) {
    margin-right: 48px;
  }

  input {
    width: 100%;
    margin-right: ${({ $size }) => ($size === 'large' ? '16px' : '0')};
  }
`;

export default SearchWrapper;
